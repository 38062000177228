import React from 'react';

import './styles.scss';

export const CBKSpinner: React.FC<{}> = () => {
  return (
    <div className="cbk-spinner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0"
        y="0"
        viewBox="0 0 372.4 300"
      >
        <style>{`
          #lid {
            animation: shacking 0.5s infinite linear;
            animation-direction: alternate;
            transform-origin: 180px 0px;
          }

          #steam-left {
            animation: grow 500ms infinite linear;
            animation-direction: alternate;
            transform-origin: center;
          }

          #steam-right {
            animation: grow 500ms infinite linear;
            animation-direction: alternate;
            animation-delay: 0.5s;
            transform-origin: center;
          }

          #fire-left,
          #fire-right {
            animation: lit 350ms infinite linear;
            animation-direction: alternate;
            transform-origin: center center;
          }
          #fire-center {
            animation: lit 350ms infinite linear;
            animation-direction: alternate;
            transform-origin: center center;
            animation-delay: 175ms;
          }

          @keyframes shacking {
            from {
                transform: rotate(-5deg);
            }
            to {
                transform: rotate(5deg);
            }
          }

          @keyframes grow {
            0% {
              transform: scale(0.9);
              opacity: 0;
            }
            25% {
              opacity: 0;
            }
            90% {
              opacity: 1;
            }
            100% {
              transform: scale(1);
            }
          }

          @keyframes lit {
            0% {
              transform: scale(0.95);

            }
            100% {
              transform: scale(1);
            }
          }
        `}</style>
        <g id="fire">
          <path id="fire-left" d="M155.3,255.2c-3.1-9.1-12.2-19.7-15.3-23.2c-1.2-1.3-2.9-2-4.7-2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-1.8,0-3.5,0.8-4.7,2
            c-3.1,3.5-12.2,14.1-15.3,23.2c-0.9,3.2-1,6.8-1,6.8c0.6,11.1,9.8,19.9,20.9,19.9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c11.1,0,20.4-8.8,20.9-19.9C156.3,262,156.2,258.3,155.3,255.2z M135.2,269.3c-4.7,0-8.5-3.8-8.5-8.5c0-2.4,3.7-8.6,8.5-14.9
            c4.8,6.3,8.5,12.5,8.5,14.9C143.7,265.4,139.9,269.3,135.2,269.3z"/>
          <path id="fire-center" d="M204.2,259.4c-3.7-10.7-14.3-23.2-18-27.3c-1.4-1.5-3.4-2.4-5.5-2.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
            c-2.1,0-4.1,0.9-5.5,2.4c-3.7,4.1-14.3,16.6-18,27.3c-1.1,3.7-1.2,8-1.2,8c0.7,13,11.6,23.4,24.6,23.4c0.1,0,0.1,0,0.2,0
            c0.1,0,0.1,0,0.2,0c13,0,23.9-10.4,24.6-23.4C205.4,267.4,205.3,263.1,204.2,259.4z M180.6,276c-5.5,0-10-4.5-10-10
            c0-2.8,4.3-10.1,10-17.5c5.7,7.4,10,14.7,10,17.5C190.6,271.5,186.1,276,180.6,276z"/>
          <path id="fire-right" d="M246.1,255.2c-3.1-9.1-12.2-19.7-15.3-23.2c-1.2-1.3-2.9-2-4.7-2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-1.8,0-3.5,0.8-4.7,2
            c-3.1,3.5-12.2,14.1-15.3,23.2c-0.9,3.2-1,6.8-1,6.8c0.6,11.1,9.8,19.9,20.9,19.9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
            c11.1,0,20.4-8.8,20.9-19.9C247.1,262,247,258.3,246.1,255.2z M226,269.3c-4.7,0-8.5-3.8-8.5-8.5c0-2.4,3.7-8.6,8.5-14.9
            c4.8,6.3,8.5,12.5,8.5,14.9C234.5,265.4,230.7,269.3,226,269.3z"/>
        </g>
        <path id="pot" d="M 302.71581,97.4 H 68.215805 c -4.1,0 -7.4,3.3 -7.4,7.4 v 18.5 c 0,10.9 8.8,19.7 19.7,19.7 h 11.2 v 42 c 0,21.1 17.100005,38.2 38.200005,38.2 h 111.1 c 21.1,0 38.2,-17.1 38.2,-38.2 v -42 h 11.1 c 10.9,0 19.7,-8.8 19.7,-19.7 v -18.5 c 0.1,-4.1 -3.2,-7.4 -7.3,-7.4 z M 91.715805,128.3 h -11.1 c -2.7,0 -5,-2.2 -5,-5 v -11.1 h 16.1 z M 264.51581,185 c 0,12.9 -10.5,23.5 -23.5,23.5 h -111 c -12.9,0 -23.5,-10.5 -23.5,-23.5 v -72.9 h 158 z m 30.9,-61.7 c 0,2.7 -2.2,5 -5,5 h -11.1 v -16.1 h 16.1 z" />
        <path id="lid" d="m 282.31581,76.492423 c 0,-5.4 -3.2,-10.2 -8,-12.3 -1.4,-0.6 -3.1,-1.4 -5,-2.3 -8.3,-3.8 -20.9,-9.6 -37.4,-14 -5,-1.4 -10.1,-2.4 -15.2,-3.3 v -11.9 c 0,-11.2 -16.1,-15.2 -31.2,-15.3 -15.1,0 -31.2,4 -31.2,15.3 v 11.9 c -5.1,0.9 -10.2,2 -15.2,3.3 -16.4,4.4 -29,10.2 -37.4,14 -1.900005,0.9 -3.600005,1.6 -5.000005,2.3 -4.9,2.1 -8.1,6.9 -8,12.3 v 8 c 0,2 0.8,3.8 2.2,5.2 1.4,1.4 3.3,2.2 5.2,2.2 H 275.11581 c 2,0 3.8,-0.8 5.2,-2.2 1.4,-1.4 2.2,-3.3 2.2,-5.2 z m -96.8,-44.3 c 8.2,0 14.1,1.5 16.5,2.7 v 7.7 c -5.5,-0.5 -11,-0.9 -16.5,-0.9 -5.5,0 -11,0.4 -16.5,0.9 v -7.7 c 2.4,-1.2 8.3,-2.7 16.5,-2.7 z m 0,44.9 h -81.5 c 1.2,-0.5 2.5,-1.1 3.9,-1.8 7.9,-3.6 19.8,-9.1 35.1,-13.2 14,-3.8 28.4,-5.7 42.6,-5.7 14.2,0 28.5,1.9 42.6,5.7 15.3,4.1 27.2,9.6 35.1,13.2 1.4,0.6 2.7,1.2 3.9,1.8 z" />
        <g id="steam-left">
          <path d="m 58.650391,80.498966 c 0.8,-0.25 1.45,-0.85 1.75,-1.7 0.45,-1.4 -0.3,-2.9 -1.7,-3.35 -1.95,-0.65 -3.45,-2.15 -4.05,-4.1 -1.05,-3.3 0.8,-6.85 4.1,-7.85 2.8,-0.85 5.85,0.35 7.3,2.85 0.75,1.3 2.35,1.75 3.65,1 1.3,-0.75 1.7,-2.35 1,-3.65 -2.7,-4.65 -8.35,-6.9 -13.5,-5.3 -5,1.55 -8.2,6.2 -8.15,11.2 -0.4,0.1 -0.75,0.15 -1.15,0.3 -5.55,1.65 -8.65,7.55 -6.9,13.05 1.7,5.55 7.6,8.6 13.15,6.9 1.4,-0.45 2.2,-1.95 1.75,-3.35 -0.45,-1.4 -1.95,-2.2 -3.35,-1.75 -2.7,0.85 -5.6,-0.65 -6.45,-3.4 -0.85,-2.7 0.7,-5.6 3.4,-6.45 0.3,-0.1 0.6,-0.15 0.85,-0.15 1.4,2.7 3.8,4.8 6.7,5.75 0.55,0.15 1.1,0.15 1.6,0 z"/>
          <path d="m 66.31139,77.042423 11.2,8.25 c 0.45,0.35 1.05,0.5 1.6,0.5 0.8,0 1.65,-0.35 2.15,-1.1 0.85,-1.2 0.6,-2.85 -0.55,-3.75 l -11.25,-8.2 c -1.2,-0.85 -2.85,-0.6 -3.75,0.55 -0.85,1.2 -0.6,2.9 0.6,3.75 z m 10.35,12.8 c 0.35,-1.45 -0.5,-2.9 -1.95,-3.25 l -9.1,-2.25 c -1.45,-0.35 -2.9,0.5 -3.25,1.95 -0.35,1.45 0.5,2.9 1.95,3.25 l 9.1,2.25 c 0.2,0.05 0.45,0.1 0.65,0.1 1.2,0 2.3,-0.8 2.6,-2.05 z"/>
        </g>
        <g id="steam-right">
          <path d="m 312.18342,80.498966 c -0.8,-0.25 -1.45,-0.85 -1.75,-1.7 -0.45,-1.4 0.3,-2.9 1.7,-3.35 1.95,-0.65 3.45,-2.15 4.05,-4.1 1.05,-3.3 -0.8,-6.85 -4.1,-7.85 -2.8,-0.85 -5.85,0.35 -7.3,2.85 -0.75,1.3 -2.35,1.75 -3.65,1 -1.3,-0.75 -1.7,-2.35 -1,-3.65 2.7,-4.65 8.35,-6.9 13.5,-5.3 5,1.55 8.2,6.2 8.15,11.2 0.4,0.1 0.75,0.15 1.15,0.3 5.55,1.65 8.65,7.55 6.9,13.05 -1.7,5.55 -7.6,8.6 -13.15,6.9 -1.4,-0.45 -2.2,-1.95 -1.75,-3.35 0.45,-1.4 1.95,-2.2 3.35,-1.75 2.7,0.85 5.6,-0.65 6.45,-3.4 0.85,-2.7 -0.7,-5.6 -3.4,-6.45 -0.3,-0.1 -0.6,-0.15 -0.85,-0.15 -1.4,2.7 -3.8,4.8 -6.7,5.75 -0.55,0.15 -1.1,0.15 -1.6,0 z"/>
          <path d="m 304.52242,77.042423 -11.2,8.25 c -0.45,0.35 -1.05,0.5 -1.6,0.5 -0.8,0 -1.65,-0.35 -2.15,-1.1 -0.85,-1.2 -0.6,-2.85 0.55,-3.75 l 11.25,-8.2 c 1.2,-0.85 2.85,-0.6 3.75,0.55 0.85,1.2 0.6,2.9 -0.6,3.75 z m -10.35,12.8 c -0.35,-1.45 0.5,-2.9 1.95,-3.25 l 9.1,-2.25 c 1.45,-0.35 2.9,0.5 3.25,1.95 0.35,1.45 -0.5,2.9 -1.95,3.25 l -9.1,2.25 c -0.2,0.05 -0.45,0.1 -0.65,0.1 -1.2,0 -2.3,-0.8 -2.6,-2.05 z"/>
        </g>
      </svg>
    </div>
  )
}

export default CBKSpinner